import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import "./Css/Loading.css";
import "./Css/AdminPanel.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Home from "./Components/Website/Home";
import AboutUs from "./Components/Website/AboutUs";
import Gallery from "./Components/Website/Gallery";
import Blog from "./Components/Website/Blog";
import ScrollToTop from "./ScrollToTop";
import CheckAvail from "./Components/Website/CheckAvail";
import AOS from "aos";
import "aos/dist/aos.css";
import ExtraPage from "./Components/Website/ExtraPage";
import PageNotFound from "./Components/Website/404";
import {
  server_post_data,
  seo_data_url,
  Website_URL_admin_panel,
} from "./ServiceConnection/serviceconnection.js";
import BlogMutiples from "./Components/Website/BlogMutiples.js";
function App() {
  const [SEOloop, setSEOloop] = useState([]);
  const match_and_return_seo_link_and_page = (
    call_function_name,
    pretty_function_name,
    dynamic_title_ss,
    favicon_sss
  ) => {
    let data_seo_link_final = "";

    if (call_function_name === "/") {
      data_seo_link_final = pretty_function_name + "~@~1";
    } else if (call_function_name === "/AboutUS") {
      data_seo_link_final = pretty_function_name + "~@~2";
    } else if (call_function_name === "/Gallery") {
      data_seo_link_final = pretty_function_name + "~@~3";
    } else if (call_function_name === "/BookHall") {
      data_seo_link_final = pretty_function_name + "~@~4";
    } else if (call_function_name.includes("blog/blog_detail")) {
      data_seo_link_final = pretty_function_name + "~@~5";
    }
    return data_seo_link_final;
  };

  const get_page_name = (page_number) => {
    let data_seo_link_final = "";

    if (page_number === "1") {
      data_seo_link_final = <Home />;
    } else if (page_number === "2") {
      data_seo_link_final = <AboutUs />;
    } else if (page_number === "3") {
      data_seo_link_final = <Gallery />;
    } else if (page_number === "4") {
      data_seo_link_final = <CheckAvail />;
    } else if (page_number === "5") {
      data_seo_link_final = <Blog />;
    }

    return data_seo_link_final;
  };

  const handleFetchData = async () => {
    await server_post_data(seo_data_url, null)
      .then((Response) => {
        if (!Response.data.error) {
          setSEOloop(Response.data.message.seo_loop);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    handleFetchData();
    AOS.init();
  }, []);

  const RedirectToExternalURL = () => {
    window.location.href = Website_URL_admin_panel;
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* Website Pages */}
        <Route path="/" element={<Home />} />
        <Route path="/AboutUS" element={<AboutUs />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/BookHall" element={<CheckAvail />} />
        <Route path="/ExtraPage" element={<ExtraPage />} />
        <Route path="/blog" element={<BlogMutiples />} />
        <Route path="/blog/blog_detail/:id" element={<Blog />} />

        {SEOloop.map((data, index) => {
          const for_loop_come = match_and_return_seo_link_and_page(
            data.call_function_name,
            data.pretty_function_name,
            data.title,
            data.favicon
          );
          const stringArray = for_loop_come.split("~@~");

          return (
            <Route
              key={index}
              path={stringArray[0]}
              element={get_page_name(stringArray[1])}
            />
          );
        })}

        {/* Website Pages */}
        {/* admin panel */}
        <Route path="/adminlogin*" element={<RedirectToExternalURL />} />
        <Route path="/admindashboard" element={<RedirectToExternalURL />} />
        <Route path="/blogDashboardView" element={<RedirectToExternalURL />} />
        <Route path="/BlogDashboardAdd" element={<RedirectToExternalURL />} />
        <Route
          path="/BlogDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route path="/seoDashboardView" element={<RedirectToExternalURL />} />
        <Route path="/SeoDashboardAdd" element={<RedirectToExternalURL />} />
        <Route
          path="/SeoDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route path="/imageDashboardView" element={<RedirectToExternalURL />} />
        <Route path="/ImageDashboardAdd" element={<RedirectToExternalURL />} />
        <Route
          path="/ImageDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route path="/menuDashboardView" element={<RedirectToExternalURL />} />
        <Route path="/menuDashboardAdd" element={<RedirectToExternalURL />} />
        <Route
          path="/menuDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/testimonialDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/testimonialDashboardAdd"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/testimonialDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route path="/teamDashboardView" element={<RedirectToExternalURL />} />
        <Route path="/teamDashboardAdd" element={<RedirectToExternalURL />} />
        <Route
          path="/teamDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/websiteDashboardAdd"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/subscribeDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/sliderDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route path="/sliderDashboardAdd" element={<RedirectToExternalURL />} />
        <Route
          path="/sliderDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route path="/hallDashboardView" element={<RedirectToExternalURL />} />
        <Route path="/hallDashboardAdd" element={<RedirectToExternalURL />} />
        <Route
          path="/hallDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/eventTimingDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/eventTimingDashboardAdd"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/eventTimingDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/eventlistDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/eventlistDashboardAdd"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/eventlistDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/gallerywebsiteDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/gallerywebsiteDashboardAdd"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/gallerywebsiteDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route path="/staffDashboardView" element={<RedirectToExternalURL />} />
        <Route path="/staffDashboardAdd" element={<RedirectToExternalURL />} />
        <Route
          path="/staffDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />

        {/* ispe kaam nhi krna h */}
        <Route
          path="/enquiryDashboardAdd"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/enquiryDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/enquiryDashboardViewDetails/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/enquiryAssignDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/enquiryCompleteDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/CustomerBookingDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/enquiryDashboardDetails/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/enquiryDashboardAgain/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/enquiryCompleteDashboardDetails/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/EnquiryDashboardConfirm/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/CustomerBookingDashboardDetails/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/CustomerBookingInvoice/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/FeedbackDashboardView"
          element={<RedirectToExternalURL />}
        />

        <Route
          path="/landmarkDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/landmarkDashboardAdd"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/landmarkDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/UsermasterDashboardView"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/UsermasterDashboardAdd"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/UsermasterDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />
        <Route
          path="/UserMasterStaffDashboardEdit/:id"
          element={<RedirectToExternalURL />}
        />

        <Route path="/*" element={<PageNotFound />} />
        {/* admin panel */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
