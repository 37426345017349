import React, { useEffect, useState, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  server_post_data,
  get_all_home_front,
} from "../../ServiceConnection/serviceconnection.js";
import {
  handleLinkClick,
  DateormateBlogChange,
} from "../../CommonJquery/CommonJquery.js";
import { Link } from "react-router-dom";
function BlogMutiples() {
  const [SEOloop, setSEOloop] = useState([]);
  const [addBlog, setaddBlog] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("admin_id", "0");
    await server_post_data(get_all_home_front, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message);

          setSEOloop(Response.data.message.data_seo);
          setaddBlog(Response.data.message.data_blog);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    const start_date = "";
    const end_date = "";
    const flag = "3";
    const call_id = "0";

    master_data_get(start_date, end_date, flag, call_id);
  }, []);

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/blog/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  return (
    <div>
      <Header />
      <section className="blog">
        <div className="blogcontainer container-lg">
          <span className="cursiveHead ">&nbsp;</span>
          <span className="cursiveHead ">&nbsp;</span>
          <h2 className="cursiveHead">Blogs</h2>
          <h3 className="normalHead">Inspiring Your Next Journey</h3>
          <div className="blogsCardsContaier">
            <div className="row m-0">
              {addBlog.map((landmar, index) => (
                <div className="col-md-4 mb-3" key={index}>
                  <Link
                    onClick={() =>
                      handleLinkClick(
                        match_and_return_seo_link(landmar.primary_id)
                      )
                    }
                  >
                    <div className="bogscard" data-aos="fade-up">
                      <img
                        src={landmar.image_name}
                        alt="Best marriage halls in thane,Wedding venues in thane,Low budget wedding hall in thane,best banquet halls in thane west,best wedding hall in thane,wedding hall in thane,Banquet Halls in Thane,perfect wedding venue,best wedding venue in thane west,perfect Banquets hall in Thane,Raghuvanshi Hall, wedding venue, Thane, Maharashtra, multi-cuisine, in-house catering, event services, bridal room, in-house décor, banquet hall, wedding testimonials, personalized events, meetings, conferences, venues"
                        title="Raghuvanshi Hall"
                      />
                      <div className="blogtext">
                        <h6>{landmar.title_name}</h6>
                        <p>
                          {DateormateBlogChange(landmar.entry_date)} |{" "}
                          {landmar.author}
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default BlogMutiples;
